.PosterCircleHolo {}
body {
  font-family: 'Lucida Grande', Verdana, Arial;
  font-size: 12px;
}

#stage {
  margin: 150px auto;
  width: 600px;
  height: 400px;
  /*
  
  Setting the perspective of the contents of the stage
  but not the stage itself
  
  */
  perspective: 800;
}

#rotate {
  margin: 0 auto;
  width: 600px;
  height: 400px;
  /* Ensure that we're in 3D space */
  transform-style: preserve-3d;
  /*
  Make the whole set of rows use the x-axis spin animation
  for a duration of 7 seconds, running infinitely and linearly
  */
  animation-name: x-spin;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ring {
  margin: 0 auto;
  height: 110px;
  width: 600px;
  transform-style: preserve-3d;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ring > :nth-child(odd) {
  background-color: #995C7F;
}

.ring > :nth-child(even) {
  background-color: #835A99;
}

.poster {
  position: absolute;
  left: 250px;
  width: 100px;
  height: 100px;
  opacity: 0.7;
  color: rgba(0,0,0,0.9);
  border-radius: 10px;
}

.poster > p {
  font-family: 'Georgia', serif;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-top: 28px;
}

/*
Set up each row to have a different animation duration
and alternating y-axis rotation directions.
*/
#ring-1 {
  animation-name: y-spin;
  animation-duration: 5s;
}

#ring-2 {
  animation-name: back-y-spin;
  animation-duration: 4s;
}

#ring-3 {
  animation-name: y-spin;
  animation-duration: 3s;
}

/*

Here we define each of the three individual animations that
we will be using to have our 3D rotation effect. The first
animation will perform a full rotation on the x-axis, we'll
use that on the whole set of objects. The second and third
animations will perform a full rotation on the y-axis in
opposite directions, alternating directions between rows.

Note that you currently have to specify an intermediate step
for rotations even when you are using individual transformation
constructs.

*/
@keyframes x-spin {
  0%    { transform: rotateX(0deg); }
  50%   { transform: rotateX(180deg); }
  100%  { transform: rotateX(360deg); }
}

@keyframes y-spin {
  0%    { transform: rotateY(0deg); }
  50%   { transform: rotateY(180deg); }
  100%  { transform: rotateY(360deg); }
}

@keyframes back-y-spin {
  0%    { transform: rotateY(360deg); }
  50%   { transform: rotateY(180deg); }
  100%  { transform: rotateY(0deg); }
}