.ListBox {
  min-width: 300px;
  width: 100%;
}


.list-item {
  display: flex; 
}
.list-item.selected {
  border: solid 2px royalblue;
  width: 100%;
}
.list-item .avatar {
  margin: 0 12px 0 24px;
}

.activeToggle {
  outline: solid 2px royalblue;
}
.selectedToggle {
  outline: solid 2px #266e05;
}
 .list-box-head {
   padding: 0 0 12px 0;
 }