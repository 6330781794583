.SocketSender {
  width: 100%;
  margin:  0 0 200px 0;
} 
/* .flex {
  display: flex;
  padding: 8px 0;
  align-items: center;
  --size: 16px
} */
.flex .indicator {
   width: var(--size);
   height: var(--size);
   border-radius: 50%;
   display: block;
   margin: 0 12px;
}

.flex .indicator.on {
  background-color: lime;
}
.flex .indicator.off {
  background-color: red;
}

  
.flex select{
  margin-left: 12px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #e0e0e0; 
 }
 .flex socket-button{
   text-transform: uppercase;
  margin-left: 12px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #e0e0e0; 
}

.flex .long-button {
  margin-right: 12px;
}

.flex .sync-button {
   margin-right: 24px;
 }

 .flex.collapse {
  height: inherit; 
  opacity: 1;
  transition: height 0.2s ease-in, opacity 0.25s ease-in;
}
.flex.collapse.on {
   height: 0; 
   padding: 0;
   margin: 0;
   opacity: 0;
   overflow: hidden; 
}