.VideoConfigSocket {}
.socket-status {
  min-width: 360px;
  border: solid 1px #e0e0e0;
  border-radius: .25em;
  padding: 12px;
}

.collapse {
  max-height: 48px;
}