 .Workspace{
  padding: 1rem 2rem 10rem 2rem;
  text-align: left;
  background-color: #f0f0f0;
  min-height: 100vh ; 
  margin-top: 64px;
}

.wide {
  /* width: calc (100vw - 380px) */
}

.Workspace.mobile {
  padding: 2rem 12px  ;
  margin-top: 32px;
}