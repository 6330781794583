.VideoManager {
  --color: aliceblue;
}

.VideoManager .button {
  margin: 0 12px 0 0;
}

.blue {
  background-color: blue !important;
}

.red {
  background-color: yellow !important;
}

.VideoManager .form {
  margin: 12px 0;
}
.VideoManager .img {
  width:100%;
  border-radius: 4px;
}

.preview {
  display: flex;
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.preview.chosen {
  /* font-style: italic; */
  color: #37a;
}
.disabled {
  font-style: italic;
  color: #ccc;
  opacity: .5;
}
.img-preview{
  width: 240px;
  height: 100px;
  margin: 0 12px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #fa2;
}
 
