.ConfirmAlertWell {
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 300;
  min-width: 100vw;
}

.ConfirmAlertWell .button {
  margin-right: 12px;
}