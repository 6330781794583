.SignInPage {
  width: 100vw;
  min-height: 100vh;
  padding: 2rem 0;
}

.subheader {
  text-align: left;
}

.SignInPage .button {
  margin: 8px;
}

.aws-logo {
  width: 32px;
  height: 32px;
  margin: 8px 0;
}

.SignInPage .card {
  border: solid 1px #f0f0f0;
}