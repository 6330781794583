.HoloPage {
  transform: rotateY(180deg);
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.HoloBox {
  opacity: 0;
  transition: opacity 1.2s linear;
}

.HoloBox.on {
  opacity: 1;
}


.updating {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  width: 100vw;
  height: 100vh;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10rem;
  text-transform: uppercase;
  opacity: 0.7;
}

.diagnostic {
  transform: rotateY(180deg);
  z-index: 300; 
  opacity: .1;
  position: fixed;
  top: 40px;
  left: 40px;
  padding: 12px; 
  min-width: 300px;
}
 

.diagnostic:hover {
  opacity: .8;
  border: solid 1px #fa0;
}

.diagnostic  li{
  display: flex;
  padding: 12px 0;
  border-bottom: dotted 1px #fefefe;
}
.diagnostic  li label{
  padding: 0 6px;
  font-weight: 600;
}
.diagnostic  li span{
  margin-left: auto;
}
.diagnostic  ul{
  list-style: none;
  padding: 0;
  margin: 0 12px;
}