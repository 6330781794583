.HoloComponentManager {
  background-color: white;
  min-height: 100vh ;
}

.HoloComponentManager table {
  border: solid 1px #999;
  margin: 8px  0; 
}
.HoloComponentManager th {
  border-bottom: solid 2px #37a;
  margin: 0;
  padding: 8px 12px;
  text-transform: capitalize;
}
.HoloComponentManager td {
  border-bottom: solid 1px #999;
  margin: 0;
  padding: 8px 12px;
}
.HoloComponentManager td.cell {
  max-width: 200px; 
}

tr.even {
  background-color: aliceblue;
}

.error {
  color: red;
  font-style: normal;
  text-decoration: underline;
}