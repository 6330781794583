.RubikHolo {
  --white-square: #ff00ff;
  --square-size: 3.6em ;
  --cube-width: 12em ;
  --cube-height: calc(var(--cube-width) / 3) ;
  opacity: 0;
  z-index: 101;
  background: #000;
}

.RubikHolo.on {
  opacity: 1;
}
@-webkit-keyframes rotate {
  from {
    transform: rotateY(-30deg) rotateX(-30deg);
  }
  to {
    transform: rotateY(690deg) rotateX(-30deg);
  }
}
@keyframes rotate {
  from {
    transform: rotateY(-30deg) rotateX(-30deg);
  }
  to {
    transform: rotateY(690deg) rotateX(-30deg);
  }
}
@-webkit-keyframes spinTopRow {
  0% {
    transform: rotateY(0deg);
  }
  5% {
    transform: rotateY(90deg);
  }
  45% {
    transform: rotateY(90deg);
  }
  50% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes spinTopRow {
  0% {
    transform: rotateY(0deg);
  }
  5% {
    transform: rotateY(90deg);
  }
  45% {
    transform: rotateY(90deg);
  }
  50% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes spinMiddleColumn {
  25% {
    transform: rotateX(0deg);
  }
  35% {
    transform: rotateX(90deg);
  }
  75% {
    transform: rotateX(90deg);
  }
  85% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@keyframes spinMiddleColumn {
  25% {
    transform: rotateX(0deg);
  }
  35% {
    transform: rotateX(90deg);
  }
  75% {
    transform: rotateX(90deg);
  }
  85% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@-webkit-keyframes spinMiddleRow {
  40% {
    transform: rotateY(0deg);
  }
  45% {
    transform: rotateY(90deg);
  }
  90% {
    transform: rotateY(90deg);
  }
  95% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes spinMiddleRow {
  40% {
    transform: rotateY(0deg);
  }
  45% {
    transform: rotateY(90deg);
  }
  90% {
    transform: rotateY(90deg);
  }
  95% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes makeOpaque1 {
  0% {
    opacity: 1;
  }
  0.01% {
    opacity: 0;
  }
  4.99% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
}
@keyframes makeOpaque1 {
  0% {
    opacity: 1;
  }
  0.01% {
    opacity: 0;
  }
  4.99% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
}
@-webkit-keyframes makeOpaque2 {
  44.99% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  49.99% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes makeOpaque2 {
  44.99% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  49.99% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes makeOpaque3 {
  24.99% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  34.99% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
}
@keyframes makeOpaque3 {
  24.99% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  34.99% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
}
@-webkit-keyframes makeOpaque4 {
  74.99% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  89.99% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@keyframes makeOpaque4 {
  74.99% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  89.99% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@-webkit-keyframes makeOpaque5 {
  39.99% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  44.99% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
}
@keyframes makeOpaque5 {
  39.99% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  44.99% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
}
@-webkit-keyframes makeOpaque6 {
  89.99% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  94.99% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}
@keyframes makeOpaque6 {
  89.99% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  94.99% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}
@-webkit-keyframes makeOpaque7 {
  74.99% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  89.99% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@keyframes makeOpaque7 {
  74.99% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  89.99% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}


body {
  height: 100%;
  width: 100%;
  /* margin:0;
  overflow: hidden; */
 
}

.container {
  perspective: 800px;
  box-shadow: 0.5em 0.5em 0.5em rgba(0, 0, 0, 0.3);
  margin: 30vh auto;
  height: 22em;
  width: 22em;
  position: relative;
  border-radius: 10%;
  background: #000;
  transition: opacity 1.2s ease-in;
  z-index: 102;
}



.container .rubiks-cube {
  width: var(--cube-width);
  height: var(--cube-width);
  margin: 5em;
  position: absolute;
  -webkit-animation: rotate 18s infinite linear;
  animation: rotate 18s infinite linear;
  transform-style: preserve-3d;
  transform: rotateY(-30deg) rotateX(-30deg) scale3d(0.7, 0.7, 0.7);
}
.container .top-clone {
  z-index: 103;
  position: absolute;
  width: var(--cube-width);
  height: var(--cube-width);
  transform-style: preserve-3d;
  -webkit-animation: spinTopRow 18s infinite linear;
  animation: spinTopRow 18s infinite linear;
}
.container .top-clone .clone-left {
  left: 0;
}
.container .top-clone .clone-left .left-one {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-clone .clone-left .left-two {
  -webkit-animation: makeOpaque3 18s infinite linear;
  animation: makeOpaque3 18s infinite linear;
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-clone .clone-left .left-three {
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-clone .clone-middle {
  left: var(--cube-height);
  -webkit-animation: makeOpaque7 18s infinite linear;
  animation: makeOpaque7 18s infinite linear;
}
.container .top-clone .clone-middle .middle-one {
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-clone .clone-middle .middle-two {
  -webkit-animation: makeOpaque3 18s infinite linear;
  animation: makeOpaque3 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-clone .clone-middle .middle-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-clone .clone-right {
  left: 8em;
}
.container .top-clone .clone-right .right-one {
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-clone .clone-right .right-two {
  -webkit-animation: makeOpaque3 18s infinite linear;
  animation: makeOpaque3 18s infinite linear;
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-clone .clone-right .right-three {
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row,
.container .middle-row,
.container .bottom-row {
  position: absolute;
  top: 0;
  width: var(--cube-width);
  height: var(--cube-height);
  transform-style: preserve-3d;
}
.container .top-row {
  top: 0;
  -webkit-animation: spinTopRow 18s infinite linear;
  animation: spinTopRow 18s infinite linear;
}
.container .top-row .front .front-one {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .front .front-two {
  -webkit-animation: makeOpaque4 18s infinite linear;
  animation: makeOpaque4 18s infinite linear;
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .front .front-three {
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .left .left-one {
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .left .left-two {
  -webkit-animation: makeOpaque3 18s infinite linear;
  animation: makeOpaque3 18s infinite linear;
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .left .left-three {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .back .back-one {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .back .back-two {
  -webkit-animation: makeOpaque4 18s infinite linear;
  animation: makeOpaque4 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .back .back-three {
  background: #ffd500;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .right .right-one {
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .right .right-two {
  -webkit-animation: makeOpaque3 18s infinite linear;
  animation: makeOpaque3 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .top-row .right .right-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row {
  top: var(--cube-height);
  -webkit-animation: spinMiddleRow 18s infinite linear;
  animation: spinMiddleRow 18s infinite linear;
}
.container .middle-row .front .front-one {
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .front .front-two {
  -webkit-animation: makeOpaque3 18s infinite linear;
  animation: makeOpaque3 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .front .front-three {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .left .left-one {
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .left .left-two {
  -webkit-animation: makeOpaque4 18s infinite linear;
  animation: makeOpaque4 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .left .left-three {
  background: #ffd500;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .back .back-one {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .back .back-two {
  -webkit-animation: makeOpaque3 18s infinite linear;
  animation: makeOpaque3 18s infinite linear;
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .back .back-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .right .right-one {
  background: #ffd500;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .right .right-two {
  -webkit-animation: makeOpaque4 18s infinite linear;
  animation: makeOpaque4 18s infinite linear;
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-row .right .right-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .bottom-row {
  top: 8em;
}
.container .bottom-row .left .left-one {
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .bottom-row .left .left-two {
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .bottom-row .left .left-three {
  background: #ffd500;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .bottom-row .right .right-one {
  background: #ffd500;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .bottom-row .right .right-two {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .bottom-row .right .right-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .row-face {
  position: absolute;
  display: block;
  width: var(--cube-width);
  height: var(--cube-height);
  top: 0;
}
.container .row-face :nth-child(1) {
  left: 0em;
  top: 50%;
  margin-top: -1.75em;
  margin-left: 0.25em;
}
.container .row-face :nth-child(2) {
  left: var(--cube-height);
  top: 50%;
  margin-top: -1.75em;
  margin-left: 0.25em;
}
.container .row-face :nth-child(3) {
  left: 8em;
  top: 50%;
  margin-top: -1.75em;
  margin-left: 0.25em;
}
.container .row-face.front {
  transform: translateZ(6em);
}
.container .row-face.left {
  transform: rotateY(-90deg) translateZ(6em);
}
.container .row-face.back {
  transform: rotateY(-180deg) translateZ(6em);
}
.container .row-face.right {
  transform: rotateY(90deg) translateZ(6em);
}
.container .left-column,
.container .middle-column,
.container .right-column {
  position: absolute;
  width: var(--cube-height);
  height: var(--cube-width);
  top: 0;
  transform-style: preserve-3d;
}
.container .left-column {
  left: 0;
}
.container .left-column .front .front-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .left-column .back .back-one {
  background: #ffd500;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .left-column .bottom .bottom-one {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .left-column .bottom .bottom-two {
  background: #ffd500;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .left-column .bottom .bottom-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column {
  left: var(--cube-height);
  -webkit-animation: spinMiddleColumn 18s infinite linear;
  animation: spinMiddleColumn 18s infinite linear;
}
.container .middle-column .front {
  -webkit-animation: makeOpaque2 18s infinite linear;
  animation: makeOpaque2 18s infinite linear;
}
.container .middle-column .front .front-one {
  -webkit-animation: makeOpaque1 18s infinite linear;
  animation: makeOpaque1 18s infinite linear;
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .front .front-two {
  -webkit-animation: makeOpaque6 18s infinite linear;
  animation: makeOpaque6 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .front .front-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .top {
  -webkit-animation: makeOpaque1 18s infinite linear;
  animation: makeOpaque1 18s infinite linear;
}
.container .middle-column .top .top-one {
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .top .top-two {
  -webkit-animation: makeOpaque5 18s infinite linear;
  animation: makeOpaque5 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .top .top-three {
  -webkit-animation: makeOpaque2 18s infinite linear;
  animation: makeOpaque2 18s infinite linear;
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .back .back-one {
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .back .back-two {
  -webkit-animation: makeOpaque6 18s infinite linear;
  animation: makeOpaque6 18s infinite linear;
  background: #ff5800;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .back .back-three {
  -webkit-animation: makeOpaque1 18s infinite linear;
  animation: makeOpaque1 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .bottom .bottom-one {
  -webkit-animation: makeOpaque2 18s infinite linear;
  animation: makeOpaque2 18s infinite linear;
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .bottom .bottom-two {
  -webkit-animation: makeOpaque5 18s infinite linear;
  animation: makeOpaque5 18s infinite linear;
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .middle-column .bottom .bottom-three {
  background: #009e60;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .right-column {
  left: 8em;
}
.container .right-column .front .front-three {
  background: #0051ba;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .right-column .back .back-one {
  background: #ffd500;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .right-column .bottom .bottom-one {
  background: var(--white-square);
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .right-column .bottom .bottom-two {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .right-column .bottom .bottom-three {
  background: #c41e3a;
  position: absolute;
  display: block;
  width: var(--square-size);
  height: var(--square-size);
  border-radius: 15%;
}
.container .column-face {
  position: absolute;
  display: block;
  width: var(--cube-height);
  height: var(--cube-width);
  left: 0;
}
.container .column-face :nth-child(1) {
  top: 0em;
  left: 50%;
  margin-left: -1.75em;
  margin-top: 0.25em;
}
.container .column-face :nth-child(2) {
  top: var(--cube-height);
  left: 50%;
  margin-left: -1.75em;
  margin-top: 0.25em;
}
.container .column-face :nth-child(3) {
  top: 8em;
  left: 50%;
  margin-left: -1.75em;
  margin-top: 0.25em;
}
.container .column-face.front {
  transform: translateZ(6em);
}
.container .column-face.top {
  transform: rotateX(90deg) translateZ(6em);
}
.container .column-face.back {
  transform: rotateX(180deg) translateZ(6em);
}
.container .column-face.bottom {
  transform: rotateX(-90deg) translateZ(6em);
}
