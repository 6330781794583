body {

  background-color: black;
}

.App {
  text-align: center;
  --accent-color: yellow;
  --second-color: rgb(17, 223, 223);
  --cube-width: 750px; 
  --half-width: calc(var(--cube-width) / 2); 
  --minus-half-width: calc(var( --half-width) * -1); 
  --logo-color: #f0a;
}

.debug {
  outline: dotted 2px red;
  box-shadow: 2px 2px 2px #f0a;
}
 
.bold {
  font-weight: 600;
}
 
.link {
  cursor: default;
  color: #37a;
  font-style: normal;
}

.link:hover {
  text-decoration: underline;
  color: black;
}

.link.bold:hover {
  text-decoration: underline;
  color: red;
}

.flex {
  display: flex;
}
.flex.center {
  align-items: center;
}
.flex.middle {
  justify-content: center;
}
.flex.left {
  justify-content: left;
}
.flex .auto {
  margin-left: auto;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.header {
  display: flex;
  align-items: center;
}
.header .avatar { 
 margin-right: 12px; 
}
.header .menu { 
 margin-right: 12px;
 white-space: nowrap; 
}
.header .menu.first {
 margin-left: auto; 
}
.caps {
  text-transform: capitalize;
}
.full {
  width: 100vw;
  height: 100vh;
}

.fab {
  position: fixed;
  bottom: 100px;
  z-index: 300;
}


.fab.right {
  right: 40px;
}


.fab.left { 
  left: 40px; 
}

.no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.max-160 {
  max-width: 160px;
}
.max-240 {
  min-width: 240px;
  max-width: 240px;
}
.max-300 {
  min-width: 300px;
  max-width: 300px;
}
.max-360 {
  min-width: 360px;
  max-width: 360px;
}


.rotated.up {
  animation: flip .3s normal forwards ease-in-out;
}
 
.rotated.turn {
  animation: turn .3s normal forwards ease-in-out;
}
 


@keyframes flip {
  0% {transform: rotate(0deg) scaleX(-1);}
  100% {transform: rotate(180deg) scaleX(-1);}
}

@keyframes turn {
  0% {transform: rotate(180deg) scaleX(-1);}
  100% {transform: rotate(270deg) scaleX(-1);}
}
