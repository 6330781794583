.RemoteControlDialog {
  padding-top: 24px;
  min-height: 100vw;
  position: absolute;
  top: 0;
}

 
.remote-content {
  padding: 24px;
}

.common-button {
  min-height: 50px;
}