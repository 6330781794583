.HoloInfoCard {
  position: relative;
}
 .Box {
  padding: 4px 24px 12px 24px;
}

.Duration {
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.short {
  max-width:160px;
}


.HoloInfoCard .sprocket {
   position: absolute;
   right: 8px;
   top: 8px;
   transition: top 0.2s ease-in;
}

.HoloInfoCard .sprocket.expanded { 
   top: 96px;
}

