.ComponentEditForm {
  width: 100%; 
  padding: 2rem;
}


.ComponentEditForm .object-photo {
  width: 90%;
  height: auto;
  border-radius: 1rem;
}