.RotatingClockHolo {}
.RotatingClock {
  transform: rotateY(180deg);
  perspective: 925px;
  position: fixed; 
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center ;
  justify-content: center;
  z-index: 101;

  opacity: 0;
  transition: opacity 1.8s linear; 
}

.on {
  opacity: 1;
}

.cube {
  transform-style: preserve-3d;
  transform: rotateY(0deg); 
  animation: spin 20s infinite ease-out;
  width: var(--cube-width); 
  height: 200px;
}

.cube.fast { 
  animation: spin 8s infinite ease-out; 
}

.cube.slow { 
  animation: spin 45s infinite ease-out; 
}
 
.cube div {
  width: var(--cube-width);
  height: 200px;
   line-height: 100px;
   text-align: center; 
   display: flex;
   font-size:10rem;
   align-items: center ;
   justify-content: center;
   position: absolute;
   color: white;      
   /* background: rgba(121, 81, 81, .6); */ 
}

.cube div span {  
  font-size:4rem; 
} 

.cube div em {  
  font-size:2rem; 
  font-style: normal;
}

.cube div.top {
   transform: rotateX(90deg); 
  margin-top: var(--minus-half-width); 
  height: var(--cube-width);
  border-radius: 50%;
  border: dotted 14px var(--accent-color) ;
  /* background: rgba(190, 0, 190, 0.6); */
}

.cube div.right {
  color: white;
   transform: rotateY(-90deg); 
  margin-left: var(--half-width);
}

.cube div.bottom {
   transform: rotateX(90deg); 
  margin-top: var(--half-width);
}

.cube div.left {
  color: white;
  /* background: rgba(190, 0, 190, 0.6); */
   transform: rotateY(90deg); 
  margin-left: calc(var(--half-width) * -1);
}

.cube div.front { 
  /* background: rgba(190, 190, 190, 0.6); */
  transform: rotateY(180deg) translateZ(calc(var(--half-width) * -1)) ;


  color: #fff; 
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    /* 0 0 21px #fff, */
    /* 0 0 42px #f0a, */
    0 0 82px #f0a,
    /* 0 0 92px #f0a,
    0 0 102px #f0a, */
    0 0 151px #f0a;
    /* animation: flicker 11.5s infinite alternate;  */
}

.cube div.back {
  color: white;
  /* background: rgba(190, 190, 0, 0.6); */
   transform: translateZ( calc(var(--half-width) * -1));
}   

@keyframes spin {
  0% {transform: rotateX(-4deg) rotateY(730deg);color:white;}
  100% {transform: rotateX(-4deg) rotateY(10deg);color:black;}
}

@keyframes flicker {
    
  0%, 18%, 22%, 25%, 53%, 57%, 100% {

      text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #0fa,
      0 0 80px #0fa,
      0 0 90px #0fa,
      0 0 100px #0fa,
      0 0 150px #0fa;
  
  }
  
  20%, 24%, 55%, 77% {        
      text-shadow: none;
  }    
}