.EditorPage {
  width: 100vw;
  min-height: 100vh;
  /* height: 100vh; */
  text-align: left;
  background-color: white !important;
  color: #222 !important;
  /* overflow: scroll; */
  /* padding: 1rem 2rem 10rem 2rem; */
}

.Paper {
  background-color: ivory; 
}

.Paper .panel { 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.Paper .panel img {
  max-width: 300px;
  margin: 4px auto;
  height: auto;
}
