.HomePage {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  margin-top: 64px;
}

.HomePage.mobile { 
  margin-top: 32px;
}

.demo-button {
  margin: 0 12px !important;
  text-transform: lowercase !important;
  /* border: solid 5px orange !important; */
  font-family:  monospace !important;
  max-width: 380px !important;
  padding-left: 8px !important;
  letter-spacing: 0.4 !important;
  font-weight: normal !important;
  font-size: .8rem !important;
}

.HomePage .left {
  padding: 2rem 3rem;
  text-align: left;
  align-items: center;
}

.HomePage .right {
  text-align: left;
  padding: 1rem ;
  background-color: #222;
  border-radius: 0px 0px 0px 10px;
  overflow: hidden;
}

.HomePage.mobile .left {
  padding: 5rem 1rem;
}

.HomePage .logo {
  margin: 0;
  border-radius: 4px;
  width: 100%; 
  height: auto;
}
.gif-video {
  width: 240px;
  height: auto;
}
.HomePage .demo {
  margin: 0;
  border-radius: 4px;
  width: 100%; 
  height: auto;
}
.HomePage img.demo-mobile { 
  width: 240px;  
  height: auto;
}

.tablet-demo {
  margin-top: 24px;
  width: 100%;  
  height: auto;
  border-radius: 16px;
}

.typo {
  color: white;
  padding: 4px 0 12px 0;
}