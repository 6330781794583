.CarouselHolo {
  font: 15px/20px Arial, sans-serif;
height: 100vh;
z-index: 101;
  --carousel-width: 300px;
  --carousel-height: 225px; 
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.CarouselHolo .carousel-container {
  margin: 0 auto; 
  width: var(--carousel-width);
  height: var(--carousel-height);
  position: relative;
  perspective: 1000px;
}

.CarouselHolo .carousel-container .carousel {
  height: 100%;
  width: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.CarouselHolo .carousel-container .carousel .item { 
  position: absolute;
  background: #000;
  width: var(--carousel-width);
  height: var(--carousel-height); 
  font-size: 5em;
  text-align: center;
  color: #FFF;
  opacity: 0.95;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CarouselHolo .carousel-container .carousel .item img {
  max-width: var(--carousel-width);
  border-radius: 10px;
  height: var(--carousel-height);
}

.a {
  transform: rotateY(0deg) translateZ(var(--carousel-width));
  background: #ed1c24;
}
.b {
  transform: rotateY(60deg) translateZ(var(--carousel-width));
  background: #0072bc;
}
.c {
  transform: rotateY(120deg) translateZ(var(--carousel-width));
  background: #39b54a;
}
.d {
  transform: rotateY(180deg) translateZ(var(--carousel-width));
  background: #f26522;
}
.e {
  transform: rotateY(240deg) translateZ(var(--carousel-width));
  background: #630460;
} 
.f {
  transform: rotateY(300deg) translateZ(var(--carousel-width));
  background: #8c6239;
} 