 
.VideoHolo {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: opacity 2.4s linear; 
  color: white;
  z-index: 100;
}

.myVideo {
  width: 100vw;
  height: 100vh;
  z-index: -12;
}


.on{
  opacity: 1;
}

.off{
  opacity: 0;
}